<template>
  <div>
    <b-modal
      id="markdown-node-modal"
      :title="`${form.uuid ? 'Update' : 'Add'} Markdown Node Properties`"
      :ok-title="`${form.uuid ? 'Update' : 'Add'}`"
      size="lg"
      static
      no-close-on-backdrop
      @ok="handleOk"
      @shown="populateForm"
      @hidden="() => { form = {}; markdownNodeForm.reset() }"
    >
      <validation-observer ref="markdownNodeForm">
        <b-overlay :show="isProcessing">
          <b-row>
            <b-col md="12">
              <!-- Name -->
              <validation-provider
                v-if="form.uuid"
                v-slot="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-group label="Name">
                  <b-form-input
                    v-model="form.name"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Description -->
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-group label="Description">
                  <b-form-textarea
                    v-model="form.description"
                    placeholder="Explain what this node does"
                    rows="5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <!-- File -->
              <!-- <validation-provider
                v-slot="{ errors }"
                name="file"
                :rules="!form.uuid ? 'required' : ''"
              >
                <b-form-group label="File">
                  <label
                    for="file-upload"
                    class="file-preview centralise cursor-pointer"
                    style="min-height: 136px;"
                  >
                    <div
                      v-if="form.uuid || form.file"
                      class="text-center"
                    >
                      <feather-icon
                        icon="FileIcon"
                        size="60"
                      />
                      <p>{{ form.uuid ? 'Uploaded File' : 'File Selected' }}</p>
                    </div>

                    <feather-icon
                      v-else
                      icon="UploadIcon"
                      size="60"
                    />
                  </label>

                  <b-form-file
                    id="file-upload"
                    v-model="uploadedFile"
                    class="hidden"
                    @change="onFileChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider> -->
            </b-col>
          </b-row>
        </b-overlay>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import {
  BRow, BCol, BOverlay, BFormTextarea, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import { NODE_TYPES } from '../nodeConstants'

// Props
const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  node: {
    type: Object,
    required: true,
  },
})

const root = getCurrentInstance().proxy
const emit = defineEmits('node-properties', 'refetch')

// Data properties
const isProcessing = ref(false)
const form = ref({
  file: null,
})
const markdownNodeForm = ref()

// Methods
const populateForm = () => {
  if (Object.keys(props.properties).length) {
    form.value = JSON.parse(JSON.stringify(props.properties))
  }
  if (props.node.uuid) form.value.name = props.node.name
}

const updateNode = () => {
  isProcessing.value = true
  const { data, ...formData } = form.value
  if (data) formData.data = data
  emit('node-properties', formData)

  if (formData.uuid) {
    const params = {
      ...props.node,
      ...formData,
      node_type: NODE_TYPES.ollamaparse,
    }

    useJwt.updateNode(params).then(response => {
      root.showSuccessMessage(response)
      root.$bvModal.hide('markdown-node-modal')
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      isProcessing.value = false
    })
  } else {
    isProcessing.value = false
    root.$bvModal.hide('markdown-node-modal')
  }
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  markdownNodeForm.value.validate().then(success => {
    if (success) {
      updateNode()
    }
  })
}
</script>
