<template>
  <b-card
    class="overflow-auto"
    :class="responseData.length ? 'response-explorer' : ''"
  >
    <b-card-header
      v-if="responseData.length"
      class="d-flex justify-content-between align-items-center p-0"
    >
      <h5
        v-if="node"
        class="text-capitalize"
      >
        {{ node.type.split('_').join(' ').toLowerCase() }}: {{ node.data.template }}
      </h5>

      <h5 v-else>
        Gateway: {{ currentModule }}
      </h5>

      <slot />
    </b-card-header>

    <div class="pb-1">
      <div
        v-for="(response, index) in responseData"
        :key="index"
        accordion
      >
        <b-card
          border-variant="primary"
          no-body
        >
          <b-card-header v-b-toggle="`response-${index}`">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                {{ response.name }}
              </h5>
              <feather-icon
                class="down-btn"
                icon="ChevronDownIcon"
              />
              <feather-icon
                class="up-btn"
                icon="ChevronUpIcon"
              />
            </div>
          </b-card-header>
          <b-collapse
            :id="`response-${index}`"
            accordion="response-explorer"
            :visible="index === 0"
            class="px-2"
          >
            <b-form-group label="Input">
              <b-form-textarea
                :value="JSON.stringify(response.input)"
                rows="4"
                placeholder="Input Data"
                disabled
              />
            </b-form-group>

            <b-form-group label="Output">
              <vue-json-pretty :data="response.output" />
            </b-form-group>
          </b-collapse>
        </b-card>
      </div>
    </div>

    <b-alert
      v-if="!responseData.length"
      class="p-2"
      variant="warning"
      show
    >
      No responses to explore!
    </b-alert>
  </b-card>
</template>

<script setup>
import store from '@/store'
import {
  BCard, BFormGroup, BFormTextarea, BCollapse, BCardHeader, BAlert, VBToggle,
} from 'bootstrap-vue'
import { computed } from 'vue'
import VueJsonPretty from 'vue-json-pretty'
import useResponseExplorer from '../useResponseExplorer'
import 'vue-json-pretty/lib/styles.css'

const { responseData } = useResponseExplorer()
const vBToggle = VBToggle

const props = defineProps({
  nodes: {
    type: [Array, Object],
    default: () => [],
  },
  selectedNode: {
    type: String,
    default: () => '',
  },
})

const node = computed(() => (props.selectedNode ? props.nodes?.filteredItems?.find(n => n.uuid === props.selectedNode) : null))
const currentModule = computed(() => store.state.dataFlow.currentModule)

</script>

<style lang="scss" scoped>
.collapsed .up-btn,
.not-collapsed .down-btn {
  display: none;
}
.response-explorer {
  height: 564px;
}
</style>
