export const MODELS = [
  { label: 'GPT 3.5', value: 'gpt35' },
  { label: 'GPT 4', value: 'gpt4' },
  { label: 'GPT 4 Turbo', value: 'gpt4turbo' },
  { label: 'GPT 4o', value: 'gpt_4o' },
  { label: 'GPT 4o Mini', value: 'gpt4o_mini' },

]

// Sidebar Option Menu
export const ENTITIES = [
  { label: 'DB Node', value: 'DB_NODE' },
  { label: 'GPT Node', value: 'GPT_NODE' },
  { label: 'HTTP Node', value: 'HTTP_NODE' },
  { label: 'Workflow Node', value: 'WORKFLOW_NODE' },
  { label: 'Shared Workflow', value: 'SHARED_WORKFLOW_NODE' },
  { label: 'Branch Node', value: 'BRANCH_NODE' },
  { label: 'Conditional Node', value: 'CONDITIONAL_NODE' },
  { label: 'GPT Conditional Node', value: 'GPT_CONDITIONAL_NODE' },
  { label: 'Join Node', value: 'JOIN_NODE' },
  { label: 'File Node', value: 'FILE_NODE' },
  { label: 'Email Node', value: 'EMAIL_NODE' },
  { label: 'Ollama Parse Node', value: 'OLLAMA_PARSE_NODE' },
  { label: 'Markdown Node', value: 'MARKDOWN_NODE' },
  { label: 'Scraper Node', value: 'SCRAPER_NODE' },
]

// class to node mapping
export const NODES = [
  'gateway', 'db-node', 'http-node', 'gpt-node', 'workflow-node', 'conditional-node', 'gpt-conditional-node', 'file-node', 'chat-ollama-node',
  'branch-node', 'shared-workflow-node', 'join-node', 'email-node', 'scraper-node', 'ollama-parse-node', 'markdown-node',
]

// map with backend
export const NODE_TYPES = {
  gateway: 'GATEWAY',
  db: 'DB_NODE',
  http: 'HTTP_NODE',
  gpt: 'GPT_NODE',
  workflow: 'WORKFLOW_NODE',
  conditional: 'CONDITIONAL_NODE',
  gptconditional: 'GPT_CONDITIONAL_NODE',
  jsonresponse: 'JSON_RESPONSE_NODE',
  file: 'FILE_NODE',
  chatollama: 'CHAT_OLLAMA_NODE',
  branch: 'BRANCH_NODE',
  sharedworkflow: 'SHARED_WORKFLOW_NODE',
  join: 'JOIN_NODE',
  email: 'EMAIL_NODE',
  scraper: 'SCRAPER_NODE',
  ollamaparse: 'OLLAMA_PARSE_NODE',
  markdown: 'MARKDOWN_NODE',
}
