<template>
  <b-modal
    id="file-node-modal"
    :title="`${form.uuid ? 'Update' : 'Add'} File Node Properties`"
    size="xl"
    :ok-title="form.uuid ? 'Update' : 'Add'"
    static
    no-close-on-backdrop
    @ok="handleOk"
    @shown="populateForm"
    @hidden="fileNodeForm.reset()"
  >
    <validation-observer ref="fileNodeForm">
      <b-overlay :show="isProcessing">
        <b-row>
          <b-col md="6">
            <!-- Description -->
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-group label="Description">
                <b-form-textarea
                  v-model="form.description"
                  placeholder="Explain what this node does"
                  rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <!-- Name -->
            <validation-provider
              v-if="form.uuid"
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group label="Name">
                <b-form-input
                  v-model="form.name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- File -->
            <validation-provider
              v-slot="{ errors }"
              name="file"
              :rules="!form.uuid ? 'required' : ''"
            >
              <b-form-group label="File">
                <label
                  for="file-upload"
                  class="file-preview centralise cursor-pointer"
                >
                  <div
                    v-if="form.uuid || previewUrl"
                    class="text-center"
                  >
                    <feather-icon
                      icon="FileIcon"
                      size="60"
                    />
                    <p>Uploaded File</p>
                  </div>

                  <feather-icon
                    v-else
                    icon="UploadIcon"
                    size="60"
                  />
                </label>
                <!-- <img
                    :src="previewUrl"
                    alt="File Preview"
                    class="img-fluid"
                    style="width: 100%; height: 350px;"
                  > -->
                <b-form-file
                  id="file-upload"
                  v-model="form.file"
                  class="hidden"
                  :placeholder="form.uuid ? 'Change file' : 'Upload file'"
                  @change="onFileChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

            </validation-provider>

            <!-- Data -->
            <!-- <validation-provider
              v-slot="{ errors }"
              name="data"
            >
              <b-form-group>
                <template #label>
                  <span>Data</span>
                  <feather-icon
                    v-b-tooltip.hover="'While performing single run, enter the data to replicate passed down data from the parent node'"
                    icon="InfoIcon"
                    :style="'margin-left: 4px'"
                  />
                </template>
                <b-form-input
                  v-model="form.data"
                  placeholder="Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider> -->
          </b-col>

        </b-row>

        <!-- Params -->
        <div>
          <div class="d-flex align-items-baseline">
            <h5 class="mt-2">
              Params:
            </h5>

            <b-button
              variant="link"
              size="sm"
              @click="form.params.push({ name: '', value: '' })"
            >
              <feather-icon icon="PlusIcon" />
              Add Params
            </b-button>
          </div>
          <b-row>
            <b-col
              v-for="param, index of form.params"
              :key="index"
              md="6"
              class="border-bottom py-1"
            >
              <b-row class="align-items-center">
                <b-col md="5">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="'name'"
                    rules="required"
                  >
                    <b-form-input
                      v-model="param.name"
                      placeholder="Param name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="value"
                    rules="required"
                  >
                    <b-form-input
                      v-model="param.value"
                      placeholder="Param value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <feather-icon
                    class="text-danger cursor-pointer"
                    icon="Trash2Icon"
                    @click="removeItem(index, 'params')"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script setup>
import {
  BRow, BCol, BModal, BFormGroup, BFormInput, BFormFile, BFormTextarea, BOverlay, BButton,
  // VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { getCurrentInstance, ref } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import { NODE_TYPES } from '../nodeConstants'

// const vBTooltip = VBTooltip
const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  node: {
    type: Object,
    required: true,
  },
})

const root = getCurrentInstance().proxy
const emit = defineEmits('node-properties', 'refetch')
const isProcessing = ref(false)
const fileNodeForm = ref()
const form = ref({
  params: [],
})
const previewUrl = ref()
// Methods
const populateForm = () => {
  if (Object.keys(props.properties).length) {
    form.value = props.properties
    if (props.node.uuid) form.value.name = props.node.name
  }
}

const removeItem = (index, field) => {
  form.value[field].splice(index, 1)
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  fileNodeForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true
      const { data, ...formData } = form.value
      if (data) formData.data = data

      emit('node-properties', formData)
      const payload = {
        ...props.node,
        ...formData,
        node_type: NODE_TYPES.file,
      }
      let formDatas = new FormData()
      if (payload.file) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in payload) {
        // eslint-disable-next-line no-prototype-builtins
          if (payload.hasOwnProperty(key)) {
            if (key === 'params') {
              formDatas.append(key, JSON.stringify(payload[key]))
            } else formDatas.append(key, payload[key])
          }
        }
      } else {
        formDatas = {
          ...payload,
          file: payload.path,
        }
      }
      if (form.value.uuid) {
        useJwt.updateNode(formDatas).then(response => {
          root.showSuccessMessage(response)
        }).finally(() => {
          root.$bvModal.hide('file-node-modal')
          isProcessing.value = false
        })
      } else {
        root.$bvModal.hide('file-node-modal')
        isProcessing.value = false
      }
    }
  })
}

const onFileChange = event => {
  const file = event.target.files[0]
  if (file) {
    form.value.file = file
    previewUrl.value = URL.createObjectURL(file)
  } else {
    form.value.file = null
    previewUrl.value = null
  }
}
</script>
