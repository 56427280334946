<template>
  <b-modal
    id="json-input-modal"
    title="Message"
    size="lg"
    ok-title="Done"
    @ok="handleOk"
  >
    <validation-observer ref="jsonModeForm">
      <b-row>
        <b-col md="3">
          <h6>Key</h6>
        </b-col>

        <b-col md="7">
          <h6>Value</h6>
        </b-col>
      </b-row>
      <div
        v-for="message, index of jsonMessages"
        :key="index"
        class="border-bottom pb-1"
      >
        <b-row class="align-items-baseline">
          <b-col md="3">
            <validation-provider
              v-slot="{ errors }"
              name="key"
              rules="required"
            >
              <b-form-input
                v-model="message.key"
                placeholder="Key"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="7">
            <validation-provider
              v-slot="{ errors }"
              name="value"
              rules="required"
            >
              <b-form-input
                v-model="message.value"
                placeholder="Value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <label :for="`parse-upload-${index}`">
                <div class="btn btn-primary btn-sm">
                  <feather-icon icon="UploadIcon" />
                </div>
              </label>

              <b-form-file
                :id="`parse-upload-${index}`"
                v-model="file"
                class="hidden"
                @change="e => onUpload(e, message.key)"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-button
              variant="danger"
              size="sm"
              @click="removeItem(index)"
            >
              <feather-icon
                class="cursor-pointer"
                icon="Trash2Icon"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </validation-observer>

    <div>
      <b-button
        variant="link"
        size="sm"
        @click="jsonMessages.push({ key: '', value: '' })"
      >
        <feather-icon icon="PlusIcon" />
        Add Key/Value Pair
      </b-button>
    </div>
  </b-modal>
</template>

<script setup>
import {
  BModal, BRow, BCol, BFormInput, BButton, BFormFile, VBTooltip, BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { getCurrentInstance, ref } from 'vue'
import useChatIde from './useChatIde'

const { jsonMessages } = useChatIde()
const jsonModeForm = ref()
const file = ref(null)
const base64Files = ref({})
const root = getCurrentInstance().proxy

const vBTooltip = VBTooltip
const emits = defineEmits('message')

// Methods
const removeItem = index => {
  jsonMessages.value.splice(index, 1)
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  jsonModeForm.value.validate().then(success => {
    if (success) {
      root.$bvModal.hide('json-input-modal')
      const messages = {}
      jsonMessages.value.forEach(({ key, value }) => {
        if (Object.keys(base64Files.value).includes(key)) {
          messages[key] = base64Files.value[key]
        } else messages[key] = value
      })
      emits('message', messages)
    }
  })
}

const onUpload = (event, key) => {
  const uploadedFile = event.target.files[0]
  const reader = new FileReader()
  reader.onloadend = () => {
    const base64split = reader.result.split('/')

    if (base64split.length > 1) {
      base64Files.value[key] = `data:@file/${base64split.slice(1).join('/')}`
    }
    jsonMessages.value.find(message => message.key === key).value = uploadedFile.name
  }
  reader.readAsDataURL(uploadedFile)
}

const isBase64File = str => {
  try {
    return btoa(atob(str)) === str
  } catch (err) {
    return false
  }
}
</script>
